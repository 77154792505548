import Link from "next/link";
import Picture from "../picture";
import ThumbnailPicture from "../thumbnailpicture";

function CupLeader({ clan, experience }) {
    return (
        <div className="w-100 text-center">
            <div className="players-table__participant__avatar mx-auto">
                <ThumbnailPicture thumbnail={clan.thumbnail} width={66} height={66} alt={clan.name} />
            </div>
            <div className="small my-1 line-clamp">{clan.name}</div>
            <div className="text-muted small">{experience}</div>
        </div>
    );
}

export default function HomeDronovCup({ leaders }) {
    return (
        <div className="container-fluid">
            <Link href="/moscow/clantournament/">
                <a className="dronov-cup">
                    <div className="row">
                        <div className="col-12 col-sm-7 dronov-cup__first-panel">
                            <Picture
                                baseUrl="/img/dronov-cup/dronov-cup"
                                alt=""
                                className="dronov-cup__cup"
                                width={85}
                                height={85}
                                isPng
                            />
                            <div className="dronov-cup__description flex-grow-1 mx-3">
                                <h3>Dronov Cup - 350 000 руб.</h3>
                                <div className="dronov-cup__subtitle">клановый турнир с 01.10.24 по 31.12.24</div>
                                <div className="text-muted">
                                    <div>Призовой фонд:</div>
                                    <div>50 000 руб. лучшему клану октября, ноября, декабря</div>
                                    <div>200 000 руб. клану, набравшему больше всего очков за 3 месяца.</div>
                                </div>
                            </div>
                            <div className="d-desktop-only dronov-cup__chevron-right">
                                <img src="/img/icons/icon-chevron-gallery-right.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 dronov-cup__leaders">
                            <div className="fw-bold mt-2 mt-sm-0 mb-2 mb-sm-1">Текущие лидеры</div>
                            <div className="d-flex justify-content-between">
                                {leaders.map((rating, idx) => (
                                    <CupLeader key={idx} clan={rating.clan} experience={rating.experience_gained} />
                                ))}
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        </div>
    );
}
